import React from 'react';
import './MobileFooter.css';
import { Link } from "react-router-dom";
import { BsBagHeartFill } from "react-icons/bs";
import { FaShop } from "react-icons/fa6";
import { FaHome } from "react-icons/fa";

const MobileFooter = () => {
    const companyID = localStorage.getItem('companyID');
    const dynamicURL = companyID ? `/home/${companyID}` : '/';

    return (
        <div className="footerr">
            <div className="footer-item">
                <FaHome />
                <span><Link to={dynamicURL}>Home</Link></span>
            </div>
            <div className="footer-item">
                <BsBagHeartFill />
                <span><Link to="/wishlist">Wishlist</Link></span>
            </div>
            <div className="footer-item">
                <FaShop />
                <span><Link to="/myorders">My Orders</Link></span>
            </div>
        </div>
    )
}

export default MobileFooter
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import { useCartContext } from '../../Contexts/CartContext';
import { useCompanyContext } from '../../Contexts/CompanyContext';
import { useProductContext } from '../../Contexts/ProductContext';
import userdp from './icons8-user-30 (1).png'
import './Navbar.css';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Badge from '@mui/material/Badge';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import NoLogo from './No_logo.png';
import { Helmet } from 'react-helmet';
import { FaHome } from "react-icons/fa";
import Shop from './shops.jpg';


export default function Navbar({ setSearchTerm }) {
  const [userName, setUserName] = useState('');
  const [addresses, setAddresses] = useState([]);
  const navigate = useNavigate();
  const [isCardVisible, setIsCardVisible] = useState(false);
  const { cartItems, clearCart } = useCartContext();
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const { companyDetails } = useCompanyContext();
  console.log("company details navbarrrrrrrrr ", companyDetails);
  // const companyId = companyDetails ?  companyDetails.company_id : '';
  // const dynamicURL = `/home/${companyId}`;
  const logoSrc = companyDetails ? `data:image/png;base64,${atob(companyDetails.company_image)}` : '';
  const companyID = localStorage.getItem('companyID');
  const dynamicURL = companyID ? `/home/${companyID}` : '/';
  console.log("logo src ================== ", logoSrc);
  const isLoggedIn = localStorage.getItem('user');
  const companyName = localStorage.getItem('companyName');
  const { products } = useProductContext();
  const [searchInput, setSearchInput] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  // const handleSearchChange = (event) => {
  //   setSearchTerm(event.target.value);
  // };

  const handleToggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    const listAddresses = async () => {
      const listAddressesurl = apiUrl + '/list_addresses';
      try {
        const response = await fetch(listAddressesurl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user: isLoggedIn })
        });

        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.status);
        }

        const addresses = await response.json();
        setAddresses(addresses.result.addresses);
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
    };

    // listAddresses();
    if (isLoggedIn) {
      listAddresses();
    }
  }, [isLoggedIn, apiUrl]);


  const handleLogout = async () => {
    try {
      // await synchronizeCartWithBackend();
      localStorage.removeItem('user');
      setUserName('');

      clearCart();

      navigate('/login');

      window.location.reload();
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  useEffect(() => {
    // Retrieve user information from localStorage
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const { user } = JSON.parse(storedUser);
      setUserName(user);
    }
  }, []); // Empty dependency array ensures this effect runs only once, on component mount

  const toggleDisplayCard = () => {
    setIsCardVisible(!isCardVisible); // Toggle the visibility state of the display card
  };

  useEffect(() => {
    // Script to handle menu functionality
    const menu = document.querySelector(".menu");
    const menuMain = menu.querySelector(".menu-main");
    const goBack = menu.querySelector(".go-back");
    const menuTrigger = document.querySelector(".mobile-menu-trigger");
    const closeMenu = menu.querySelector(".mobile-menu-close");
    let subMenu;

    const handleMenuItemClick = (e) => {
      if (!menu.classList.contains("active")) {
        return;
      }
      if (e.target.closest(".menu-item-has-children")) {
        const hasChildren = e.target.closest(".menu-item-has-children");
        showSubMenu(hasChildren);
      } else {
        // Close the menu when a menu item is clicked
        toggleMenu();
      }
    };

    const handleGoBackClick = () => {
      hideSubMenu();
    };

    const handleMenuTriggerClick = () => {
      toggleMenu();
    };

    const handleCloseMenuClick = () => {
      toggleMenu();
    };

    const handleOverlayClick = () => {
      toggleMenu();
    };

    const toggleMenu = () => {
      menu.classList.toggle("active");
      document.querySelector(".menu-overlay").classList.toggle("active");
    };

    const showSubMenu = (hasChildren) => {
      subMenu = hasChildren.querySelector(".sub-menu");
      subMenu.classList.add("active");
      subMenu.style.animation = "slideLeft 0.5s ease forwards";
      const menuTitle = hasChildren.querySelector("i").parentNode.childNodes[0].textContent;
      menu.querySelector(".current-menu-title").innerHTML = menuTitle;
      menu.querySelector(".mobile-menu-head").classList.add("active");
    };

    const hideSubMenu = () => {
      subMenu.style.animation = "slideRight 0.5s ease forwards";
      setTimeout(() => {
        subMenu.classList.remove("active");
      }, 300);
      menu.querySelector(".current-menu-title").innerHTML = "";
      menu.querySelector(".mobile-menu-head").classList.remove("active");
    };

    window.onresize = () => {
      if (window.innerWidth > 991) {
        if (menu.classList.contains("active")) {
          toggleMenu();
        }
      }
    };

    // menuMain.addEventListener("click", handleMenuItemClick);
    goBack.addEventListener("click", handleGoBackClick);
    // menuTrigger.addEventListener("click", handleMenuTriggerClick);
    closeMenu.addEventListener("click", handleCloseMenuClick);
    document.querySelector(".menu-overlay").addEventListener("click", handleOverlayClick);

    // Cleanup
    return () => {
      // menuMain.removeEventListener("click", handleMenuItemClick);
      goBack.removeEventListener("click", handleGoBackClick);
      // menuTrigger.removeEventListener("click", handleMenuTriggerClick);
      closeMenu.removeEventListener("click", handleCloseMenuClick);
      // document.querySelector(".menu-overlay").removeEventListener("click", handleOverlayClick);
    };
  }, []);

  const handleSearchChange = (event) => {
    const input = event.target.value;
    setSearchInput(input);
    // setSearchTerm(input);

    // Generate search suggestions based on product names
    if (input.length > 0) {
      const filtered = products
        .filter(product => product.name.toLowerCase().includes(input.toLowerCase()))
        .slice(0, 10); // Limit to 10 suggestions
      setSuggestions(filtered);
    } else {
      setSuggestions([]);
    }
  };

  // Handle Enter key press for navigation to /search
  // const handleKeyPress = (event) => {
  //   if (event.key === 'Enter' && searchInput.trim()) {
  //     navigate(`/search?query=${searchInput}`);
  //     setSuggestions([]); // Clear suggestions after search
  //   }
  // };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (searchInput.trim()) {
        navigate(`/search?query=${searchInput}`);
      } else {
        navigate('/');
      }
      setSuggestions([]); // Clear suggestions after search
    }
  };


  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  // '/favicon.ico'  #4f73f7
  return (
    <ThemeProvider theme={darkTheme}>
      <Helmet>
        <title>{companyName ? companyName : 'Shop'}</title>
        <link rel="icon" href={logoSrc ? logoSrc : Shop} />
      </Helmet>
      <header className="header" style={{ backgroundColor: '#375E97' }}>
        <div className="container-fluid">
          <div className="row v-center">
            <div className="header-item item-left">
              <div className="logo">
                <Link to={dynamicURL}>
                  {/* <img src={logonew} alt="Default Logo" className='header-logo' /> */}
                  {logoSrc ? <img src={logoSrc} className='header-logo' alt="Company Logo"
                    style={{ height: '1%', width: '65px', borderRadius: '50%' }} /> : <img src={NoLogo} className='header-logo' alt="Company Logo"
                      style={{ height: '1%', width: '65px', borderRadius: '50%' }} />}
                </Link>
              </div>
            </div>
            {/* menu start here */}
            <div className="header-item item-center">
              <div className="menu-overlay"></div>
              <nav className="menu">
                <div className="mobile-menu-head">
                  <div className="go-back">
                    <i className="fa fa-angle-left" />
                  </div>
                  <div className="current-menu-title" />
                  <div className="mobile-menu-close">×</div>
                </div>

              </nav>
              <div className="address-search-container">
                <div className="address">
                  <Link to="/address">
                    <span>Delivery</span><br />
                    {addresses && addresses.length > 0 ? (
                      <span key={0}>{addresses[0].street}, {addresses[0].city}, {addresses[0].zip}, {addresses[0].country}</span>
                    ) : (<span>H42, Pocket B,Noida, Sector 11</span>)}
                  </Link>
                </div>
                <div className="search-bar">
                  {/* <Link to="/search"> */}
                  <input
                    type="text"
                    placeholder="Search"
                    className="search-input"
                    value={searchInput}
                    onChange={handleSearchChange}
                    onKeyDown={handleKeyPress}
                  />
                  {/* </Link> */}
                  {suggestions.length > 0 && (
                    <div className="suggestions-list">
                      {suggestions.map((suggestion) => (
                        <div
                          key={suggestion.id}
                          className="suggestion-item"
                          onClick={() => {
                            navigate(`/search?query=${suggestion.name}`);
                            setSuggestions([]); // Clear suggestions on click
                          }}
                        >
                          {suggestion.name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className='home-icon'>
                  <Link to={dynamicURL}><FaHome /></Link>
                </div>
              </div>
              {dropdownOpen && (
                <div className="dropdown-nav-buttons">
                  <div className="dropdown-nav-item" >
                    <Link to={dynamicURL}>Shop</Link>
                  </div>
                  <div className="dropdown-nav-item" >
                    <Link to="/myorders">My Orders</Link>
                  </div>
                  <div className="dropdown-nav-item" >
                    <Link to="/wishlist">My Wishlist</Link>
                  </div>
                </div>
              )}
            </div>
            {/* menu end here */}
            <style
              dangerouslySetInnerHTML={{
                __html:
                  "\n        .cart-btn{\n          line-height: 10px;\n          background: red;\n          padding: 10px;\n          margin: 0 10px;\n          border-radius: 24px\n        }\n      "
              }}
            />

            <div className="header-item item-right">
              <br />
              <div className='cart-icon'>
                <Link to="/cart" className="text-white">
                  <Badge badgeContent={cartItems.length} color="primary">
                    <ShoppingCartIcon width='20px' height='20px'></ShoppingCartIcon>
                    {/* <img src="/img/icons8-cart-64.png" width='20px' height='20px' alt="Cart"></img> */}
                  </Badge>
                </Link>
              </div>
              {/* {userName ? ( */}
              <>
                {/* Display card */}
                {/* Display picture and user name */}
                <div className="user-info" onClick={toggleDisplayCard}>
                  <div className="display-picture">
                    <img src={userdp} alt="" />
                  </div>
                  <span className="user-name">{userName}</span>
                </div>
                <Menu
                  className='profile-icon'
                  anchorEl={isCardVisible ? document.querySelector('.user-info') : null}
                  open={isCardVisible}
                  onClose={() => setIsCardVisible(false)}
                >
                  {userName ?
                    (<>
                      {/* <MenuItem ><Link to={dynamicURL}>Home</Link></MenuItem> */}
                      <MenuItem><Link to="/myorders">My Orders</Link></MenuItem>
                      <MenuItem ><Link to="/wishlist">My Wishlist</Link></MenuItem>
                      <MenuItem onClick={handleLogout}>Sign Out</MenuItem>
                    </>
                    ) : (
                      <MenuItem><Link to="/login">Sign In</Link></MenuItem>
                    )}
                </Menu>
              </>
            </div>
          </div>
        </div>
      </header>
    </ThemeProvider >

  );
};
